import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  Campaign, CampaignState,
  CompaniesApiService,
  CompanyProduct,
  CompanyProfile,
  CompanyVerificationStatusValue,
  CompanyVisibilityStatusValue,
  DictionaryValue, ProductResponse, ProductState
} from '../../../api/cs';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../shared/services/auth.service';
import {AttributesService} from '../../../shared/services/attributes.service';
import {AdminViewService} from "../../../shared/services/admin-view.service";
import {PlatformImageService} from "../../../shared/services/platform-image.service";
import {BehaviorSubject, finalize, Observable} from "rxjs";
import {ToastService} from "../../../shared/services/toast.service";
import {CampaignCreateComponent} from "../../../campaign/components/campaign-create/campaign-create.component";
import {MatDialog} from "@angular/material/dialog";
import {EditCompanyMainInfoFormComponent} from "./edit-company-main-info-form/edit-company-main-info-form.component";
import {BreadcrumbService} from "xng-breadcrumb";
import {Title} from "@angular/platform-browser";
import {ProductCreateComponent} from "../../../products/components/product-create/product-create.component";
import {ContactFormComponent} from "../contact-form/contact-form.component";
import {FileUploadModal} from "../../../shared/components/file-upload-modal/file-upload-modal";
import {LoaderService} from "../../../shared/services/loader.service";
import {AuthComponent} from "../../../core/components/auth-modal-window/auth.component";

@Component({
  selector: 'cs-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  company: CompanyProfile;

  private companyAlias: string | null;
  isAdminView: boolean;
  logoHovered: boolean;
  adminView$: Observable<boolean>;
  company$: Observable<CompanyProfile>;
  companyProducts$: BehaviorSubject<CompanyProduct[]>;
  companyCampaigns$: BehaviorSubject<CompanyCampaign[]>;
  companyVisibilityStatus: CompanyVisibilityStatusValue | undefined;
  visibilityStatusValue = CompanyVisibilityStatusValue;
  companyVerificationStatus: CompanyVerificationStatusValue | undefined;
  verificationStatusValue = CompanyVerificationStatusValue;
  companyNewVisibilityStatusDescription: string;
  companyNewVerificationStatusDescription: string;

  canEdit: boolean = false;
  isSupplyChainBuilderUser: boolean;
  baseUrl: string = '';
  companySiteLink: string = '';
  showContactButton: boolean = false;
  showPublishButton: boolean = false;
  showVerified: boolean = false;
  showTagline: boolean = false;
  showSummary: boolean = false;
  showPlatforms: boolean = false;
  showAdminViewSwitch: boolean = false;
  showBusinessType: boolean = false;
  showIndustryFocuses: boolean = false;
  showSupplyChains: boolean = false;
  showCustomerFocus: boolean = false;
  showPrivateInfo: boolean = false;
  showWebsite: boolean = false;
  showAddress: boolean = false;
  showPhone: boolean = false;
  showGeneralInfo: boolean = false;
  showLocation: boolean = false;
  showSize: boolean = false;
  showFoundationYear: boolean = false;
  companyOwner: boolean = false;
  s3DefaultApi = environment.s3DefaultApi;
  s3BucketName = environment.s3BucketName;

  csGreen = "#22b07d";
  lightGray = "#bbb";
  darkGray = "#444";
  lightRed = "#daa";

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private attributesService: AttributesService,
              private companiesApiService: CompaniesApiService,
              private toastService: ToastService,
              private dialog: MatDialog,
              public platformImageService: PlatformImageService,
              public adminViewService: AdminViewService,
              private breadcrumbService: BreadcrumbService,
              private titleService: Title,
              private loader: LoaderService) {
  }

  public ngOnInit(): void {
    this.loader.show();
    this.setBaseUrl();
    this.isAdminView = this.adminViewService.isAdminView;
    this.isSupplyChainBuilderUser = this.isSupplyChainUser();
    this.adminView$ = this.adminViewService.adminView;
    this.adminView$.subscribe(isAdminView => {
      this.isAdminView = isAdminView;
      this.resolveVisibility();
      this.setVisibleProducts();
    });
    this.attributesService.companyAttrsLoaded$.subscribe();
    this.activatedRoute.params.subscribe((params: Params) => {
      this.companyAlias = params['company-alias']; // TODO: handle 'own' case better
      if (this.companyAlias == 'own') {
        this.companyAlias = this.authService.userInfo.value?.company?.alias || '';
      }
      this.load();
    })
  }

  private isSupplyChainUser(): boolean {
    return this.authService.userInfo.getValue()?.role == "SUPPLY_CHAIN_USER";
  }

  getDictionaryValuesText(dictionaryValues?: DictionaryValue[]): string {
    return dictionaryValues?.map(v => v.value).join(', ') ?? '';
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  showSuccessWindow(): void {
    this.toastService.success("Request has been sent");
  }

  changeAccessStatus(): void {
    let newStatus: CompanyVisibilityStatusValue;
    if (this.company.visibilityStatus == CompanyVisibilityStatusValue.PUBLISHED) {
      newStatus = CompanyVisibilityStatusValue.UNPUBLISHED;
    } else {
      newStatus = CompanyVisibilityStatusValue.PUBLISHED;
    }
    this.companiesApiService.changeCompanyVisibilityStatus({
      id: this.company.id || '',
      status: newStatus
    }).subscribe(() => {
      this.companyVisibilityStatus = newStatus;
      this.companyNewVisibilityStatusDescription = this.getNewAccessibilityStatusDescription();
      this.load();
      this.toastService.success("Company accessibility status has changed to " + newStatus.toLowerCase());
    });
  }

  changeVerificationStatus(): void {
    let newStatus: CompanyVerificationStatusValue;
    if (this.company.verificationStatus == CompanyVerificationStatusValue.VERIFIED) {
      newStatus = CompanyVerificationStatusValue.UNVERIFIED;
    } else {
      newStatus = CompanyVerificationStatusValue.VERIFIED;
    }
    this.companiesApiService.changeCompanyVerificationStatus({
      id: this.company.id || '',
      status: newStatus
    }).subscribe(() => {
      this.companyVerificationStatus = newStatus;
      this.companyNewVerificationStatusDescription = this.getNewVerificationStatusDescription();
      this.load();
      this.toastService.success("Company verification status has changed to " + newStatus.toLowerCase());
    });
  }

  load(): void {
    if (this.companyAlias) {
      this.company$ = this.companiesApiService.getCompanyProfile({
        alias: this.companyAlias
      }).pipe(finalize(() => this.loader.hide()));
      this.company$.subscribe((company?) => {
        this.company = company!;
        this.titleService.setTitle(`${this.company.name} | Cultured Supply` );
        this.breadcrumbService.set('companies/:company-alias', this.company?.name || '');
        this.companyCampaigns$ = new BehaviorSubject<CompanyCampaign[]>(
          (company?.campaigns?.filter(value => value?.state == CampaignState.ACTIVE)) || []);
        this.isAdmin();
        this.setCompanySiteLink();
        this.resolveVisibility();
        this.setVisibleProducts();
        this.isCompanyOwner();
        this.companyVisibilityStatus = company?.visibilityStatus;
        this.companyNewVisibilityStatusDescription = this.getNewAccessibilityStatusDescription();
        this.companyVerificationStatus = company?.verificationStatus;
        this.companyNewVerificationStatusDescription = this.getNewVerificationStatusDescription();
      }, error => {
        if (+error.status == 419) {
          this.toastService.error("Sorry, your should be logged in for watching this company");
          let matDialogRef = this.dialog.open(AuthComponent, { panelClass: 'custom-dialog-container', data: { mode: "logIn", withoutRedirection: true }});
          matDialogRef
              .afterOpened()
              .subscribe(() => matDialogRef.componentInstance.loginCompleted?.subscribe(() => {
                this.load();
              }));
        }
      })
    }
  }

  private setVisibleProducts(): void {
    if (this.isAdminView) {
      this.companyProducts$ = new BehaviorSubject<CompanyProduct[]>(
        this.company?.products || []);
    } else {
      this.companyProducts$ = new BehaviorSubject<CompanyProduct[]>(
        this.company?.products?.filter(value => value?.state === ProductState.ACTIVE) || [])
    }
  }

  public toCamelCase(productState: ProductState | undefined): string | undefined {
    let firstSymbol = productState?.substring(0, 1).toUpperCase();
    let rest = productState?.substring(1).toLowerCase();
    return firstSymbol?.concat(rest || '');
  }

  private getNewAccessibilityStatusDescription(): string {
    if (this.companyVisibilityStatus == CompanyVisibilityStatusValue.PUBLISHED) {
      return "Unpublish";
    } else {
      return "Publish";
    }
  }

  private getNewVerificationStatusDescription(): string {
    if(this.companyVerificationStatus == CompanyVerificationStatusValue.VERIFIED) {
      return "Unverify";
    } else {
      return "Verify";
    }
  }

  private setBaseUrl(): void {
    this.baseUrl = environment.baseUrl;
  }

  private isAdmin(): void {
    this.canEdit = this.authService.isAdmin();
  }

  private isCompanyOwner(): void {
    this.companyOwner = this.authService.userInfo.value?.company?.id === this.company.id;
  }

  private setCompanySiteLink(): void {
    this.companySiteLink = this.company?.website?.startsWith('http') ?
      this.company.website! :
      'http://' + this.company.website;
  }

  private resolveVisibility(): void {
    this.showContactButton = this.isContactButtonVisible();
    this.showPublishButton = this.isPublishButtonVisible();
    this.showVerified = this.isVerifiedFlagVisible();
    this.showTagline = this.isTaglineVisible();
    this.showSummary = this.isSummaryVisible();
    this.showPlatforms = this.isPlatformsVisible();
    this.showAdminViewSwitch = this.isAdminViewSwitchVisible();
    this.showBusinessType = this.isBusinessTypeVisible();
    this.showIndustryFocuses = this.isIndustryFocusesVisible();
    this.showSupplyChains = this.isSupplyChainsVisible();
    this.showCustomerFocus = this.isCustomerFocusVisible();
    this.showWebsite = this.isWebsiteVisible();
    this.showAddress = this.isAddressVisible();
    this.showPhone = this.isPhoneVisible();
    this.showLocation = this.isLocationVisible();
    this.showSize = this.isSizeVisible();
    this.showFoundationYear = this.isFoundationYearVisible();
    this.showPrivateInfo = this.isPrivateInfoVisible();
    this.showGeneralInfo = this.isGeneralInfoVisible();
  }

  private isContactButtonVisible(): boolean {
    return !(this.authService.isAdmin() ||
        this.companyAlias == 'own' ||
        this.authService.isOwner(this.company.id || '')) ||
      this.authService.isAdmin() && !this.isAdminView;
  }

  private isPublishButtonVisible(): boolean {
    return this.authService.isAdmin() && this.isAdminView;
  }

  private isVerifiedFlagVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('verificationStatus') || this.isAdminView) &&
      this.company.verificationStatus == CompanyVerificationStatusValue.VERIFIED;
  }

  private isTaglineVisible(): boolean {
    return this.attributesService.isShownCompanyAttribute('tagline') || this.isAdminView;
  }

  private isSummaryVisible(): boolean {
    return this.attributesService.isShownCompanyAttribute('description') || this.isAdminView;
  }

  private isPlatformsVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('platforms') &&
        !!this.company.platforms) ||
      this.isAdminView;
  }

  private isAdminViewSwitchVisible(): boolean {
    return this.canEdit && !!this.adminView$;
  }

  private isBusinessTypeVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('businessTypes') &&
        this.getDictionaryValuesText(this.company.businessTypes).length > 0) ||
      this.isAdminView;
  }

  private isIndustryFocusesVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('industryFocuses') &&
        this.getDictionaryValuesText(this.company.industryFocuses)?.length > 0) ||
      this.isAdminView;
  }

  private isSupplyChainsVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('supplyChains') &&
        this.getDictionaryValuesText(this.company.supplyChains).length > 0) ||
      this.isAdminView;
  }

  private isCustomerFocusVisible(): boolean {
    return !!this.company.customerFocus || this.isAdminView;
  }

  private isPrivateInfoVisible(): boolean {
    return (this.showWebsite ||
      this.showAddress ||
      this.showPhone) &&
      this.isAdminView;
  }

  private isWebsiteVisible(): boolean {
    return this.authService.isAdmin() &&
      !!this.company.website &&
      this.isAdminView;
  }

  private isAddressVisible(): boolean {
    return this.authService.isAdmin() &&
      !!this.company.address &&
      this.isAdminView;
  }

  private isPhoneVisible(): boolean {
    return this.authService.isAdmin() &&
      !!this.company.phoneNumber &&
      this.isAdminView;
  }

  private isGeneralInfoVisible(): boolean {
    return this.showLocation ||
      this.showSize ||
      this.showFoundationYear ||
      this.isAdminView;
  }

  private isLocationVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('location') &&
      !!this.company.location) ||
      this.isAdminView;
  }

  private isSizeVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('companySize') &&
      !!this.company.companySize) ||
      this.isAdminView;
  }

  private isFoundationYearVisible(): boolean {
    return (this.attributesService.isShownCompanyAttribute('yearFounded') &&
      !!this.company.yearFounded) ||
      this.isAdminView;
  }

  showLogoEditModal(): void {
    let matDialogRef = this.dialog.open(FileUploadModal,
        {
          panelClass: 'custom-dialog-container',
          data: { isImage: true, initialImage: this.company.logoImage }
        });
    matDialogRef.afterOpened()
        .subscribe(() => matDialogRef.componentInstance.reference.subscribe((
            reference => {
                this.companiesApiService.updateCompanyLogo({
                  id: this.company.id || '',
                  fileUpdateRequest: {
                    reference
                  }
                }).pipe(finalize(() => this.loader.hide()))
                    .subscribe(() => {
                      this.company.logoImage = reference;
                      matDialogRef.close();
                    });
            }
        )))
  }

  addNewProduct(): void {
    let matDialogRef = this.dialog.open(ProductCreateComponent, {panelClass: 'custom-dialog-container',
      data: {company: this.company}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.productResponse?.subscribe(
        (product: ProductResponse) => this.updateProductList(product)
      ));
  }

  addNewCampaign(): void {
    let matDialogRef = this.dialog.open(CampaignCreateComponent, {panelClass: 'custom-dialog-container',
      data: {company: this.company, campaign: null}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.campaignResponse?.subscribe(
        (campaign: Campaign) => this.updateCampaignList(campaign)
      ));
  }

  editCampaign(campaign: Campaign): void {
    let matDialogRef = this.dialog.open(CampaignCreateComponent, {panelClass: 'custom-dialog-container',
      data: {company: this.company, campaign: campaign}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.campaignResponse?.subscribe(
        (campaign: Campaign) => this.updateCampaignList(campaign)
      ));
  }

  showMainInfoEditWindow() {
    let matDialogRef = this.dialog.open(EditCompanyMainInfoFormComponent, {panelClass: 'custom-dialog-container',
      data: {company: this.company}});
    matDialogRef
      .afterOpened()
      .subscribe(() => this.dialog.afterOpened.subscribe(d => d.componentInstance.viewChanged.subscribe(
        () => this.adminViewService.changeAdminView())
      ));
    matDialogRef
      .afterClosed()
      .subscribe(() => this.load());
  }

  showContactRequestModal() {
    let matDialogRef = this.dialog.open(ContactFormComponent, {panelClass: 'custom-dialog-container',
      data: {company: this.company}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.submitted?.subscribe(
        () => this.toastService.success("Contact Request was successfully sent")
      ));
  }

  private updateProductList(product: ProductResponse): void {
    let wasFound: boolean = false;
    let companyProducts: CompanyProduct[] = [];
    this.companyProducts$.getValue().forEach(prd => {
      if (prd.id == product.id) {
        companyProducts.push(ProfileComponent.mapProductToCompanyProduct(product));
        wasFound = true;
      } else {
        companyProducts.push(prd);
      }
    });
    if (!wasFound) {
      companyProducts?.push(ProfileComponent.mapProductToCompanyProduct(product));
    }
    this.companyProducts$.next(companyProducts);
  }

  private updateCampaignList(campaign: Campaign): void {
    let wasFound: boolean = false;
    let companyCampaigns: CompanyCampaign[] = [];
    this.companyCampaigns$.getValue().forEach(cmp => {
      if (cmp.id == campaign.id) {
        companyCampaigns.push(ProfileComponent.mapCampaignToCompanyCampaign(campaign));
        wasFound = true;
      } else {
        companyCampaigns.push(cmp);
      }
    });
    if (!wasFound) {
      companyCampaigns?.push(ProfileComponent.mapCampaignToCompanyCampaign(campaign));
    }
    this.companyCampaigns$.next(companyCampaigns);
  }

  private static mapCampaignToCompanyCampaign(campaign: Campaign): CompanyCampaign {
    return {
      id: campaign.id,
      title: campaign.title,
      subtitle: campaign.subtitle,
      alias: campaign.alias,
      industryFocuses: campaign.industryFocuses,
      deliveryDate: campaign.deliveryDate,
      productId: campaign.productId,
      goalAmount: campaign.goalAmount,
      category: campaign.productCategory,
      currentAmount: 0,
      type: campaign.productType
    };
  }

  private static mapProductToCompanyProduct(product: ProductResponse): CompanyProduct {
    return {
      id: product.id,
      verified: product.headingInfo.verified,
      name: product.headingInfo.name,
      alias: product.alias,
      productImage: product.profileImage,
      type: product.generalInfo.type,
      industryFocuses: product.generalInfo.industryFocuses,
      chassis: product.headingInfo.chassis,
      category: product.generalInfo.category,
    };
  }

  goToProduct(alias: string | undefined): void {
    this.router.navigateByUrl('/companies/' + this.company.alias + '/products/' + alias).then();
  }

  calcNumberOfSlidesInCarousel(): number {
    return this.companyCampaigns$.getValue().length > 1 ? 2 : 0;
  }
}

export interface CompanyCampaign {
  id?: string;
  title?: string;
  subtitle?: string;
  alias?: string;
  industryFocuses?: DictionaryValue[];
  deliveryDate?: string;
  productId?: string;
  goalAmount?: number;
  category?: DictionaryValue;
  currentAmount?: number;
  type?: DictionaryValue;
}
