import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {
  Certification,
  CertificationsApiService,
  CertificationSearchResult,
  CompaniesApiService,
  CompanyProfile,
  DictionaryValue,
  FileMetadata,
  FilesApiService,
  ProductAddress,
  ProductResponse,
  ProductsApiService,
  ProductState, SupplyChainFlow
} from '../../../api/cs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../shared/services/auth.service';
import {DictionariesService} from '../../../shared/services/dictionaries.service';
import fileUtils from '../../../shared/utils/file-utils';
import {LoaderService} from '../../../shared/services/loader.service';
import {finalize, Observable} from 'rxjs';
import {AttributesService} from '../../../shared/services/attributes.service';
import {AdminViewService} from "../../../shared/services/admin-view.service";
import {PlatformImageService} from "../../../shared/services/platform-image.service";
import {DOCUMENT} from "@angular/common";
import {ToastService} from "../../../shared/services/toast.service";
import {BreadcrumbService} from "xng-breadcrumb";
import {Title} from "@angular/platform-browser";
import {MatDialog} from "@angular/material/dialog";
import {ProductHeadingFormComponent} from "./product-heading-form/product-heading-form.component";
import {ProductInfoFormComponent} from "./product-info-form/product-info-form.component";
import {
  ProductAvailabilityPricingFormComponent
} from "./product-availability-pricing-form/product-availability-pricing-form.component";
import {ProductAddressFormComponent} from "./product-address-form/product-address-form.component";
import {ProductFileFormComponent} from "./product-file-form/product-file-form.component";
import {ProductCertificationsFormComponent} from "./product-certifications-form/product-certifications-form.component";
import {ProductConfirmationFormComponent} from "./product-confirmation-form/product-confirmation-form.component";
import {SampleRequestModal} from "../../../payment/sample-request-modal/sample-request-modal.component";
import {BuyingRequestModalComponent} from "../buying-request-modal/buying-request-modal.component";
import {
  ProductQuickStatusChangeModalComponent
} from "./product-quick-status-change-modal/product-quick-status-change-modal.component";
import {ProductPromotionStatus} from "../../../api/cs/model/product-featuring-status";
import {AuthComponent} from "../../../core/components/auth-modal-window/auth.component";
import {RoutingHistoryService} from "../../../shared/services/routing-history.service";
import {Gallery, GalleryItem, ImageItem, YoutubeItem} from "ng-gallery";
import { Lightbox } from 'ng-gallery/lightbox';

@Component({
  selector: 'cs-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  product: ProductResponse;
  productAddress: ProductAddress[];
  company: CompanyProfile;
  certifications: Certification[];
  filesMap = new Map<string, FileMetadata>();

  isAdmin: boolean;
  isSupplyChainBuilderUser: boolean;
  isRoutedFromFeaturedList: boolean;
  canEdit: boolean;
  baseUrl: string;
  showAdminViewSwitch: boolean = false;
  showSupplyLocationsBlock: boolean = false;
  showSupplyLocations: boolean = false;
  showVerified: boolean = false;
  showDescription: boolean = false;
  showDevelopmentStage: boolean = false;
  showCustomerFocus: boolean = false;
  showType: boolean = false;
  showCategory: boolean = false;
  showIndustryFocuses: boolean = false;
  showFormat: boolean = false;
  showRecommendation: boolean = false;
  showProductReplacement: boolean = false;
  showBioContent: boolean = false;
  showPricing: boolean = false;
  showCapacity: boolean = false;
  showPackaging: boolean = false;
  showShippingDestination: boolean = false;
  showLeadTime: boolean = false;
  showVerificationButton: boolean = false;

  previousURL: string;

  mediaFiles: GalleryItem[] = [];
  imagesRef: string[] = [];

  BLOCKS: {INFO: string, PRICING: string, FILES: string} = {
    INFO: 'productInfoBlock',
    PRICING: 'pricingBlock',
    FILES: 'filesBlock',
  };

  private productAlias: string | null;
  isAdminView: boolean;
  adminView$: Observable<boolean>;
  s3BucketName = environment.s3BucketName;
  s3DefaultApi = environment.s3DefaultApi;

  developmentStages: DictionaryValue[] = [];

  productVerificationStatusDescription: string;
  productNewVerificationStatusDescription: string;

  productPromotionStatusDescription: string;
  productNewPromotionStatusDescription: string;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private dictionariesService: DictionariesService,
              private certificationsApiService: CertificationsApiService,
              private authService: AuthService,
              private loader: LoaderService,
              private dialog: MatDialog,
              private attributesService: AttributesService,
              private filesApiService: FilesApiService,
              private productsApiService: ProductsApiService,
              private companiesApiService: CompaniesApiService,
              public platformImageService: PlatformImageService,
              public adminViewService: AdminViewService,
              @Inject(DOCUMENT) document: Document,
              public toastService: ToastService,
              private breadcrumbService: BreadcrumbService,
              private titleService: Title,
              private routingHistoryService: RoutingHistoryService,
              public gallery: Gallery,
              public lightbox: Lightbox) {
  }

  public ngOnInit(): void {
    this.setBaseUrl();
    this.isRoutedFromFeaturedList = this.isRoutedFromFeaturedProductsList();
    this.isSupplyChainBuilderUser = this.isSupplyChainUser();
    this.isAdminView = this.adminViewService.isAdminView;
    this.adminView$ = this.adminViewService.adminView;
    this.adminView$.subscribe(isAdminView => {
      this.isAdminView = isAdminView;
      this.resolveVisibility();
    });
    this.attributesService.productAttrsLoaded$.subscribe();
    this.dictionariesService.loaded$.subscribe(value => {
      if (value) {
        this.setDevelopmentStages();
      }
    })
    this.activatedRoute.params.subscribe((params: Params) => {
      this.productAlias = params['product-alias'];
      this.load();
    });
  }

  private isSupplyChainUser(): boolean {
    return this.authService.userInfo.getValue()?.role == "SUPPLY_CHAIN_USER";
  }

  private isRoutedFromFeaturedProductsList(): boolean {
      this.previousURL = this.routingHistoryService.getPreviousUrl() || '';
      return this.routingHistoryService.getPreviousUrl()?.includes("products/featured") || false;
  }

  public routeBack(): void {
    this.router.navigateByUrl(this.previousURL).then();
  }

  private setDevelopmentStages(): void {
    this.developmentStages = this.dictionariesService.getDictionaryByName('product-development-stage');
  }

  getDictionaryValuesText(dictionaryValues?: DictionaryValue[]): string {
    return this.dictionariesService.getDictionaryValuesText(dictionaryValues);
  }

  load(): void {
    if (this.productAlias) {
      this.loader.show();
      this.productsApiService.getProductProfile({
        alias: this.productAlias
      }).pipe(finalize(() => this.loader.hide()))
        .subscribe((product) => {
          this.setPermissions(product);
          this.updatePageContent(product);
          this.setGalleryImages(product);
        if (product.companyId) {
          this.companiesApiService.getCompany({
            id: product.companyId
          }).subscribe((company) => {
            this.company = company;
          })
        }
      }, error => {
          if (+error.status == 419) {
            this.toastService.error("Sorry, your should be logged in for watching this product");
            let matDialogRef = this.dialog.open(AuthComponent, { panelClass: 'custom-dialog-container', data: { mode: "logIn", withoutRedirection: true }});
            matDialogRef
                .afterOpened()
                .subscribe(() => matDialogRef.componentInstance.loginCompleted?.subscribe(() => {
                  this.load();
                }));
          }
        });
    }
  }

  private setGalleryImages(product: ProductResponse) {
    this.mediaFiles = [];
    product.headingInfo.images?.forEach(img => {
      let url = `https://${this.s3BucketName}.${this.s3DefaultApi}/images/${img.reference}`;
      this.imagesRef.push(url);
      this.mediaFiles.push(
          new ImageItem({
            src: url,
            thumb: url
          })
      );
    });
    if (!!product.headingInfo.videoLink) {
      this.mediaFiles.push(new YoutubeItem({
        type: 'video',
        src: product.headingInfo.videoLink?.substring(product.headingInfo.videoLink?.lastIndexOf("/") + 1),
        thumb: "https://i.ytimg.com/vi/" + product.headingInfo.videoLink?.substring(product.headingInfo.videoLink?.lastIndexOf("/") + 1) + "/hqdefault.jpg"
      }));
    }
    const lightboxRef = this.gallery.ref('lightbox');
    lightboxRef.load(this.mediaFiles);
  }

  private getProductAddresses(): void {
    this.productsApiService.getProductAddressSearchResult({
      productId: this.product.id
    }).pipe(finalize(() => this.loader.hide()))
      .subscribe((productAddress) => {
        this.productAddress = productAddress.items || [];
        this.resolveVisibility();
      });
  }

  private getProductCertifications(): void {
    this.certificationsApiService.getCertificationSearchResult({
      productId: this.product.id
    }).subscribe(({items}: CertificationSearchResult) => {
      this.certifications = items ?? [];
      this.certifications.forEach((cert) => {
        if (cert.document) {
          this.filesApiService.getFileMetadata({
            reference: cert.document
          }).subscribe((res) => {
            this.filesMap.set(cert.document || '', res);
            this.resolveVisibility();
          });
        }
      })
    });
  }

  downloadFile(document?: string, fileName?: string): void {
    if (document) {
      this.filesApiService.getFileContent({
        reference: document, isImage: false
      }).subscribe((res) => {
        const name = fileName || this.filesMap.get(document)?.name || 'certification'
        fileUtils.downloadFile(res, name);
      });
    }
  }

  scrollToBlock(blockId: string): void {
    // @ts-ignore
    const el:HTMLElement = document.getElementById(blockId);
    el.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
  }

  showSuccessWindow(): void {
    this.toastService.success("Request has been sent");
  }

  private setBaseUrl(): void {
    this.baseUrl = environment.baseUrl;
  }

  private setPermissions(product: ProductResponse): void {
    this.isAdmin = this.authService.isAdmin();
    this.canEdit = this.authService.isAdmin() ||
      (product.headingInfo.state == ProductState.DRAFT &&
        this.authService.userInfo.getValue()?.company?.id == product.companyId);
  }

  private resolveVisibility(): void {
    this.showAdminViewSwitch = this.isAdminViewSwitchVisible();
    this.showSupplyLocationsBlock = this.isSupplyLocationsBlockVisible();
    this.showSupplyLocations = this.isSupplyLocationsVisible();
    this.showVerified = this.isVerifiedVisible();
    this.showDescription = this.isDescriptionVisible();
    this.showDevelopmentStage = this.isDevelopmentStageVisible();
    this.showCustomerFocus = this.isCustomerFocusVisible();
    this.showType = this.isTypeVisible();
    this.showCategory = this.isCategoryVisible();
    this.showIndustryFocuses = this.isIndustryFocusesVisible();
    this.showFormat = this.isFormatVisible();
    this.showRecommendation = this.isRecommendationVisible();
    this.showProductReplacement = this.isProductReplacementVisible();
    this.showBioContent = this.isBioContentVisible();
    this.showPricing = this.isPricingVisible();
    this.showCapacity = this.isCapacityVisible();
    this.showShippingDestination = this.isShippingDestinationVisible();
    this.showLeadTime = this.isLeadTimeVisible();
    this.showPackaging = this.isPackagingVisible();
    this.showVerificationButton = this.isVerificationButtonVisible();
  }

  private isAdminViewSwitchVisible(): boolean {
    return this.isAdmin && !!this.adminView$;
  }

  private isSupplyLocationsBlockVisible(): boolean {
    return (this.productAddress?.length || 0) > 0
      && this.productAddress.map(address => address.location).filter(location => !!location).length > 0;
  }

  private isSupplyLocationsVisible(): boolean {
    return (this.productAddress?.length || 0) > 0
      && this.productAddress.map(address => address.location).filter(location => !!location).length > 0;
  }

  private isVerifiedVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('verified') ||
        this.isAdminView) &&
      !!this.product?.headingInfo?.verified;
  }

  private isDescriptionVisible(): boolean {
    return this.attributesService.isShownProductAttribute('description') ||
      this.isAdminView;
  }

  private isDevelopmentStageVisible(): boolean {
    return this.attributesService.isShownProductAttribute('developmentStage') ||
      this.isAdminView;
  }

  private isCustomerFocusVisible(): boolean {
    return !!this.product.generalInfo.customerFocus && this.product.generalInfo.customerFocus?.length > 0;
  }

  private isTypeVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('type') &&
      !!this.product.generalInfo.type?.length) ||
      this.isAdminView;
  }

  private isCategoryVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('category') &&
      !!this.product.generalInfo.category?.length) ||
      this.isAdminView;
  }

  private isIndustryFocusesVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('industryFocuses') &&
        !!this.getDictionaryValuesText(this.product.generalInfo.industryFocuses)?.length) ||
      this.isAdminView;
  }

  private isFormatVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('format') &&
      !!this.getDictionaryValuesText(this.product.generalInfo.format).length) ||
      this.isAdminView;
  }

  private isRecommendationVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('recommendation') &&
      !!this.product.generalInfo.recommendation) ||
      this.isAdminView;
  }

  private isProductReplacementVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('replaces') &&
        !!this.product.generalInfo.replaces) ||
      this.isAdminView;
  }

  private isBioContentVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('bioBasedContentPart') &&
      !!this.product.generalInfo.bioBasedContentPart) ||
      this.isAdminView;
  }

  private isPricingVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('pricing') &&
      !!this.product.pricingInfo.pricing) ||
      this.isAdminView;
  }

  private isCapacityVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('productionCapacity') &&
        !!this.product.pricingInfo.productionCapacity) ||
      this.isAdminView;
  }

  private isShippingDestinationVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('shippingDestination') &&
      !!this.product.pricingInfo.shippingDestination) ||
      this.isAdminView;
  }

  private isLeadTimeVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('leadTime') &&
      !!this.product.pricingInfo.leadTime) ||
      this.isAdminView;
  }

  private isPackagingVisible(): boolean {
    return (this.attributesService.isShownProductAttribute('packaging') &&
        !!this.product.pricingInfo.packaging) ||
      this.isAdminView;
  }

  private isVerificationButtonVisible(): boolean {
    return this.authService.isAdmin() && this.isAdminView;
  }

  showVerificationModal(): void {
      let matDialogRef = this.dialog.open(ProductQuickStatusChangeModalComponent,
          {
            panelClass: 'custom-dialog-container',
            data: { mode: "verification", newStatusDescription: this.getNewVerificationStatusDescription() }
          });
      matDialogRef
          .afterOpened()
          .subscribe(() => matDialogRef.componentInstance.changeVerificationStatus?.subscribe((isInitiate: boolean) => {
              if (isInitiate) {
                this.changeVerificationStatus();
              }
          }));
  }

  changeVerificationStatus(): void {
    this.productsApiService.updateProductHeading({
        id: this.product.id,
        productHeadingInfo: {
          name: this.product.headingInfo.name,
          description: this.product.headingInfo.description,
          developmentStage: this.product.headingInfo.developmentStage || {},
          chassis: this.product.headingInfo.chassis || [],
          state: this.product.headingInfo.state,
          verified: !this.product.headingInfo.verified
        }
    }).subscribe(() => {
      this.productVerificationStatusDescription = this.getVerificationStatusDescription();
      this.productNewVerificationStatusDescription = this.getNewVerificationStatusDescription();
      this.load();
      this.toastService.success("Product verification status has changed to " + this.productNewVerificationStatusDescription.toLowerCase());
    });
  }

  changePromotionStatus(): void {
    this.productsApiService.updateProductHeading({
      id: this.product.id,
      productHeadingInfo: {
        name: this.product.headingInfo.name,
        description: this.product.headingInfo.description,
        developmentStage: this.product.headingInfo.developmentStage || {},
        chassis: this.product.headingInfo.chassis || [],
        state: this.product.headingInfo.state,
        promotionStatus: this.product.headingInfo.promotionStatus == "BASIC"
            ? ProductPromotionStatus.FEATURED : ProductPromotionStatus.BASIC
      }
    }).subscribe(() => {
      this.productPromotionStatusDescription = this.getPromotionStatusDescription();
      this.productNewPromotionStatusDescription = this.getNewPromotionStatusDescription();
      this.load();
      this.toastService.success("Product promotion status has changed to " + this.productNewPromotionStatusDescription.toLowerCase());
    });
  }

  private getVerificationStatusDescription(): string {
      if(this.product.headingInfo.verified) {
          return "Verify";
      } else {
          return "Unverify";
      }
  }
  private getNewVerificationStatusDescription(): string {
    if(this.product.headingInfo.verified) {
      return "Unverify";
    } else {
      return "Verify";
    }
  }

  private getPromotionStatusDescription(): string {
    if(this.product.headingInfo.promotionStatus == "BASIC") {
      return "Demote";
    } else {
      return "Promote";
    }
  }

  private getNewPromotionStatusDescription(): string {
    if(this.product.headingInfo.promotionStatus == "BASIC") {
      return "Promote";
    } else {
      return "Demote";
    }
  }

  getSupplyFlowLastValue(supplyChainFlow: SupplyChainFlow | undefined): string[] {
    let lastLevelValues: string[] = [];
    if (!!supplyChainFlow) {
      for (let [level] of Object.keys(supplyChainFlow)
        .sort((a, b) => Number.parseInt(a) - Number.parseInt(b))) {
        let supplyChainFlowElement = supplyChainFlow[level as keyof SupplyChainFlow];
        if (supplyChainFlowElement != null && supplyChainFlowElement.length > 0) {
          lastLevelValues = supplyChainFlowElement;
        }
      }
    }
    return lastLevelValues.map(value =>
      value.replace(new RegExp("\\d+(\\.\\d+)*\\.?"), "").trim());
  }

  showHeadingInfoModal() {
    let matDialogRef = this.dialog.open(ProductHeadingFormComponent, {panelClass: 'custom-dialog-container',
      data: {product: this.product}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updatedProduct?.subscribe((product: ProductResponse) => {
        this.updatePageContent(product);
        this.toastService.success("Product info was successfully updated");
      }));
  }

  showGeneralInfoModal() {
    let matDialogRef = this.dialog.open(ProductInfoFormComponent, {panelClass: 'custom-dialog-container',
      data: {product: this.product}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updatedProduct?.subscribe((product: ProductResponse) => {
        this.updatePageContent(product);
        this.toastService.success("Product info was successfully updated");
      }));
  }

  showPricingInfoModal() {
    let matDialogRef = this.dialog.open(ProductAvailabilityPricingFormComponent, {panelClass: 'custom-dialog-container',
      data: {product: this.product}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updatedProduct?.subscribe((product: ProductResponse) => {
        this.updatePageContent(product);
        this.toastService.success("Product info was successfully updated");
      }));
  }

  showAddressEditModal(addressId: string | undefined) {
    let matDialogRef = this.dialog.open(ProductAddressFormComponent, {panelClass: 'custom-dialog-container',
      data: {product: this.product, addressId: addressId}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updated?.subscribe(() => {
        this.getProductAddresses();
        this.toastService.success("Product address was successfully updated");
      }));
  }

  showCertificationCreateModal() {
    let matDialogRef = this.dialog.open(ProductCertificationsFormComponent, {panelClass: 'custom-dialog-container',
      data: {product: this.product}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updated?.subscribe((cert) => {
        this.certifications.push(cert);
        this.getProductCertifications();
        this.toastService.success("Product certificate was successfully updated");
      }));
  }

  showFileAddingModal(fileType: 'PSS' | 'SDS' | 'LCA') {
    let matDialogRef = this.dialog.open(ProductFileFormComponent, {panelClass: 'custom-dialog-container',
      data: {product: this.product, fileType: fileType}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updated?.subscribe(reference => {
        switch (fileType) {
          case "PSS":
            this.product.specSheet = reference;
            break;
          case "SDS":
            this.product.safetyDataSheet = reference;
            break;
          case "LCA":
            this.product.lcaSheet = reference;
            break;
        }
        this.getProductCertifications();
        this.toastService.success("Product certificate was successfully updated");
      }));
  }

  showProductAddressDeleteConfirmationModal(address: ProductAddress) {
    let matDialogRef = this.dialog.open(ProductConfirmationFormComponent, {panelClass: 'custom-dialog-container',
      data: {type: "Address", object: address}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updated?.subscribe(() => {
        this.getProductAddresses();
        this.toastService.success("Product address was successfully deleted");
      }));
  }

  showCertificationDeleteConfirmationModal(certification: Certification) {
    let matDialogRef = this.dialog.open(ProductConfirmationFormComponent, {panelClass: 'custom-dialog-container',
      data: {type: "Certificate", object: certification}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updated?.subscribe(() => {
        this.getProductCertifications();
        this.toastService.success("Product certificate was successfully deleted");
      }));
  }

  showFileDeleteConfirmationModal(type: string) {
    let matDialogRef = this.dialog.open(ProductConfirmationFormComponent, {panelClass: 'custom-dialog-container',
      data: {type: type, object: this.product}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.updatedProduct?.subscribe((product: ProductResponse) => {
        this.updatePageContent(product);
        this.toastService.success("Product " + type + " was successfully deleted");
      }));
  }

  showBuyingRequestModal() {
    let matDialogRef = this.dialog.open(BuyingRequestModalComponent, {panelClass: 'custom-dialog-container',
      data: {product: this.product}});
    matDialogRef
      .afterOpened()
      .subscribe(() => matDialogRef.componentInstance.submitted?.subscribe(() => {
        this.toastService.success("Buying Request was successfully sent");
      }));
  }

  buySample() {
    this.dialog.open(SampleRequestModal, {
      panelClass: 'custom-dialog-container',
      data: { product: this.product }
    });
  }

  private updatePageContent(product: ProductResponse): void {
    this.product = product;
    this.productVerificationStatusDescription = this.getVerificationStatusDescription();
    this.productNewVerificationStatusDescription = this.getNewVerificationStatusDescription();
    this.productPromotionStatusDescription = this.getPromotionStatusDescription();
    this.productNewPromotionStatusDescription = this.getNewPromotionStatusDescription();
    this.titleService.setTitle(`${product.headingInfo.name} | Cultured Supply`);
    this.breadcrumbService.set('/companies/:company-alias', this.product?.companyName || 'Company');
    this.breadcrumbService.set('/companies/:company-alias/products/:product-alias', this.product?.headingInfo.name || 'Product');
    this.getProductAddresses();
    this.getProductCertifications();
    this.setGalleryImages(product);
    this.resolveVisibility();
  }
}
