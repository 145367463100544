import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DictionariesService} from '../../../shared/services/dictionaries.service';
import {DictionaryValue} from '../../../api/cs';
import {ValidatorsService} from "../../../core/util/validators.service";
import {Observable} from "rxjs";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'cs-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit {

  @Input() form: FormGroup;
  dictionariesServiceLoaded$: Observable<any>;
  images: File[] = [];

  @Input() chassis: string[];
  @Input() categories: string[];
  @Input() types: string[];
  @Input() algaeTypes: string[];
  @Input() customerFocus: string[];

  constructor(private dictionariesService: DictionariesService,
              private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.dictionariesServiceLoaded$ = this.dictionariesService.loaded$;
  }

  getCategories(): string[] {
    return this.categories;
  }

  getChassis(): string[] {
    return this.chassis;
  }

  getDevelopmentStages(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('product-development-stage', true);
  }

  getIndustryFocuses(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('common-industry-focus', true);
  }

  getProductTypes(): string[] {
    return this.types;
  }

  getFormats(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('product-format', true);
  }

  getAlgaeTypes(): string[] {
    return this.algaeTypes;
  }

  getCustomerFocuses(): string[] {
    return this.customerFocus;
  }

  onlyNumbers($event: KeyboardEvent) {
    ValidatorsService.onlyNumbers($event);
  }

  getImagePreview(index: number): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.images[index])) ?? '';
  }

  onFileChoose({target}: Event): void {
    const fileInput = target as HTMLInputElement;
    const files = fileInput.files;
    if ((files?.length || 0) > 0) {
      if ((files?.item(0)?.size || 0) > 20_000_000) {
        this.form.controls["imageRef"].setErrors({"too-big": true});
        return;
      } else if (files && files?.length > 6 || this.images.length >= 6) {
        this.form.controls["imageRef"].setErrors({"big-amount-files": true});
        return;
      } else {
        this.form.controls["imageRef"].setErrors(null);
      }
    }
    if (files instanceof FileList && files.length) {
      let filesArray = Array.from(files);
      filesArray.forEach(file => this.images.push(file));
      this.form.get('images')?.setValue(this.images);
      fileInput.value = '';
    }
  }
}
