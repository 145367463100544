<section class="container">
  <h1>Dashboard</h1>
  <div class="columns is-justify-content-space-between is-desktop">
    <div class="column is-three-fifths dashboard-list">
      <div class="columns is-flex is-align-content-space-between dashboard-item">
        <div class="item-icon-holder">
          <img src="/assets/images/new-home/speaker.png">
        </div>
        <div class="column is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
          <h3 *ngIf="!isAdmin">My Campaigns <span *ngIf="false" class="newest-block">New buyer requests <span class="newest-counter">2</span></span></h3>
          <h3 *ngIf="isAdmin">Campaigns <span *ngIf="false" class="newest-block">New buyer requests <span class="newest-counter">2</span></span></h3>
          <p>View/manage campaigns and orders you’ve received.</p>
          <p class="green-text">{{user?.activeCampaignsCount}} active</p>
        </div>
        <div class="is-flex is-align-items-center item-detail">
          <a *ngIf="!isAdmin" routerLink="/companies/{{user?.company?.alias}}/campaigns">Details ></a>
          <a *ngIf="isAdmin" routerLink="/campaigns">Details ></a>
        </div>
      </div>
      <div class="columns is-flex is-align-content-space-between dashboard-item">
        <div class="item-icon-holder">
          <img src="/assets/images/new-home/box.png">
        </div>
        <div class="column is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
          <h3 *ngIf="!isAdmin">My Orders <span *ngIf="false" class="newest-block">New buyer requests <span class="newest-counter">2</span></span></h3>
          <h3 *ngIf="isAdmin">Orders <span *ngIf="false" class="newest-block">New buyer requests <span class="newest-counter">2</span></span></h3>
          <p>View/manage purchases you have made.</p>
          <p class="green-text">{{user?.activeOrdersCount}} active</p>
        </div>
        <div class="is-flex is-align-items-center is-3 item-detail">
          <a routerLink="/profile/orders">Details ></a>
        </div>
      </div>
      <div class="columns is-flex is-align-content-space-between dashboard-item">
        <div class="item-icon-holder">
          <img src="/assets/images/new-home/list.png">
        </div>
        <div class="column is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
          <h3 *ngIf="!isAdmin">My Products <span *ngIf="false" class="newest-block">New buyer requests <span class="newest-counter">2</span></span></h3>
          <h3 *ngIf="isAdmin">Products <span *ngIf="false" class="newest-block">New buyer requests <span class="newest-counter">2</span></span></h3>
          <p>View/manage your product listings.</p>
          <p class="green-text">{{user?.activeProductsCount}} active</p>
        </div>
        <div class="is-flex is-align-items-center is-3 item-detail">
          <a *ngIf="!isAdmin" routerLink="/companies/{{user?.company?.alias}}/products">Details ></a>
          <a *ngIf="isAdmin" routerLink="/products">Details ></a>
        </div>
      </div>
    </div>
    <div class="column is-one-third profile" *ngIf="user?.company">
      <div class="profile-info">
        <img src="https://{{s3BucketName}}.{{s3DefaultApi}}/images/{{user?.company?.logoImage}}">
        <h2>Company profile</h2>
        <div class="group">
          <h5 class="green-text">Name:</h5>
          <h5>{{user?.company?.name}}</h5>
        </div>
        <div class="group">
          <h5 class="green-text">Subtitle:</h5>
          <h5>{{user?.company?.tagline}}</h5>
        </div>
        <a routerLink="/companies/{{user?.company?.alias}}">View full profile ></a>
      </div>
    </div>
  </div>
</section>
