<div class="modal-card">
  <header class="modal-card-head">
    <p class="modal-card-title has-text-centered has-text-weight-bold">Product General Info Editing</p>
    <span class="icon cs-icon-button" aria-label="close" (click)="confirmClose()">
    <i class="fa fa-lg fa-times"></i>
  </span>
  </header>
  <form mat-dialog-content
        id="general-info-modal-card"
        class="modal-card-body pt-6"
        *ngIf="form"
        [formGroup]="form"
        (ngSubmit)="submit()">
    <div class="field mb-0" *ngIf="adminViewService.isAdmin">
      <div class="control view-switch is-flex is-flex-direction-row-reverse">
        <input type="checkbox"
               name="adminViewMainInfo"
               id="adminViewMainInfo"
               class="switch is-success is-rtl"
               (change)="adminViewService.changeAdminView()"
               [checked]="isAdminView"
        >
        <label class="label" for="adminViewMainInfo">Admin View</label>
      </div>
    </div>

    <div class="field pb-4" formGroupName="type">
      <label class="label">Product Type:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.type[type || '']"
             [class.mt-3]="!first"
             *ngFor="let type of types; let first = first"
      >
        <input type="checkbox" [formControlName]="type || ''">
        {{ type }}
      </label>
    </div>

    <div class="field pb-4" formGroupName="category">
      <label class="label">Product Category:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.category?.[category || '']"
             [class.mt-3]="!first"
             *ngFor="let category of categories; let first = first"
      >
        <input type="checkbox" [formControlName]="category || ''">
        {{ category }}
      </label>
    </div>

    <div class="field pb-4" formGroupName="algaeType">
      <label class="label">Product Algae Type:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.algaeType?.[algaeType || '']"
             [class.mt-3]="!first"
             *ngFor="let algaeType of algaeTypes; let first = first"
      >
        <input type="checkbox" [formControlName]="algaeType || ''">
        {{ algaeType | toCamelCase }}
      </label>
    </div>

    <div class="field pb-4" formGroupName="customerFocus">
      <label class="label">Product Customer Focus:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.customerFocus?.[customerFocus || '']"
             [class.mt-3]="!first"
             *ngFor="let customerFocus of customerFocuses; let first = first"
      >
        <input type="checkbox" [formControlName]="customerFocus || ''">
        {{ customerFocus | toCamelCase }}
      </label>
    </div>

    <div class="field pb-4" formGroupName="supplyChainFlow">
      <label class="label">Product Supply Chain Flow:</label>
      <div class = "my-5" *ngFor="let level of supplyChainFlow | keyvalue">
        <label class="has-text-weight-semibold">Level {{level.key}}:</label>
        <label class="checkbox cs-checkbox px-4"
               [formGroupName]="level.key"
               [class.checked]="form.value.supplyChainFlow?.[level.key || '']?.[value || '']"
               [class.mt-3]="!first"
               *ngFor="let value of level.value; let first = first"
        >
          <input type="checkbox"  [formControlName]="value || ''">
          {{ value | toCamelCase }}
        </label>
      </div>
    </div>

    <div class="field pb-4" *ngIf="showProcessing || isAdminView">
      <label class="label">Processing:</label>
      <div class="control">
        <div class="is-flex is-flex-direction-column">
          <label class="radio mx-0 cs-radio px-4"
                 [for]=undefined
                 [class.checked]="form.value.processing == undefined"
          >
            <input type="radio"
                   name="processing"
                   formControlName="processing"
                   [id]=undefined
                   [value]=undefined
                   [checked]="form.value.processing == undefined ? 'checked' : ''"
                   [defaultChecked]="form.value.processing == undefined ? 'checked' : ''"
            >
            Not Selected
          </label>
          <label class="radio mx-0 cs-radio px-4 mt-3"
                 [for]="processing.key"
                 [class.checked]="form.value.processing == processing.key"
                 *ngFor="let processing of productProcessings"
          >
            <input type="radio"
                   name="processing"
                   [id]="processing.key"
                   [value]="processing.key"
                   formControlName="processing"
                   [checked]="form.value.processing == processing.key ? 'checked' : ''"
                   [defaultChecked]="form.value.processing == processing.key ? 'checked' : ''"
            >
            {{processing.value}}
          </label>
        </div>
      </div>
    </div>

    <div class="field pb-4" formGroupName="format" *ngIf="showFormat || isAdminView">
      <label class="label">Formats:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.format[format?.key || '']"
             [class.mt-3]="!first"
             *ngFor="let format of formats; let first = first"
      >
        <input type="checkbox" [formControlName]="format.key || ''">
        {{format.value}}
      </label>
    </div>

    <div class="field pb-4" *ngIf="showRecommendation || isAdminView">
      <label class="label">Product Applications</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="Chainsaws" formControlName="recommendation">
      </div>
    </div>
    <div class="field pb-4" *ngIf="showProductReplacement || isAdminView">
      <label class="label">Product Replaces</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="Chainsaws" formControlName="productReplacement">
      </div>
    </div>
    <div class="field pb-4" *ngIf="showBioContent">
      <label class="label">Bio-based Content %:</label>
      <div class="control">
        <input class="input is-medium" type="text" placeholder="99" formControlName="bioContent">
      </div>
      <span
        *ngIf="!form.get('bioContent')!.valid && form.get('bioContent')!.touched"
        class="help-block">Enter valid part of bio-based content</span>
    </div>

    <div class="field pb-4" formGroupName="industryFocuses"
         *ngIf="showIndustryFocuses || isAdminView">
      <label class="label">Industry:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.industryFocuses[industryFocus.key || '']"
             [class.mt-3]="!first"
             *ngFor="let industryFocus of industryFocuses; let first = first"
      >
        <input type="checkbox" [formControlName]="industryFocus.key || ''">
        {{industryFocus.value}}
      </label>
    </div>

    <div class="field pb-4" formGroupName="bioSources" *ngIf="showBioSources || isAdminView">
      <label class="label">Bio Sources:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.bioSources[source.key || '']"
             [class.mt-3]="!first"
             *ngFor="let source of bioSources; let first = first"
      >
        <input type="checkbox" [formControlName]="source.key || ''">
        {{source.value}}
      </label>
    </div>

    <div class="field pb-4" formGroupName="materialTypes" *ngIf="showMaterialTypes || isAdminView">
      <label class="label">Material types:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.materialTypes[type.key || '']"
             [class.mt-3]="!first"
             *ngFor="let type of materialTypes; let first = first"
      >
        <input type="checkbox" [formControlName]="type.key || ''">
        {{type.value}}
      </label>
    </div>

    <div class="field pb-4" formGroupName="properties" *ngIf="showProperties || isAdminView">
      <label class="label">Properties:</label>
      <label class="checkbox cs-checkbox px-4"
             [class.checked]="form.value.properties?.[prop.key || '']"
             [class.mt-3]="!first"
             *ngFor="let prop of properties; let first = first"
      >
        <input type="checkbox" [formControlName]="prop.key || ''">
        {{prop.value}}
      </label>
    </div>

    <div class="field is-grouped is-grouped-centered mt-6">
      <div class="control py-4">
        <button type="submit" class="button wide is-success has-text-weight-bold is-fullwidth">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
