import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DictionariesService} from '../../../../shared/services/dictionaries.service';
import {ProductResponse, ProductsApiService} from '../../../../api/cs';
import {LoaderService} from '../../../../shared/services/loader.service';
import {finalize} from 'rxjs';
import {AttributesService} from '../../../../shared/services/attributes.service';
import {AdminViewService} from "../../../../shared/services/admin-view.service";
import {ValidatorsService} from "../../../../core/util/validators.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  ConfirmationWindowComponent
} from "../../../../core/components/confirmation-window/confirmation-window.component";

@Component({
  selector: 'cs-product-availability-pricing-form',
  templateUrl: './product-availability-pricing-form.component.html',
  styleUrls: ['./product-availability-pricing-form.component.scss']
})
export class ProductAvailabilityPricingFormComponent implements OnInit {

  form: FormGroup;

  private product: ProductResponse | undefined;
  isAdminView: boolean;

  showPricing: boolean;
  showShippingDestination: boolean;
  showLeadTime: boolean;
  showCapacity: boolean;
  showPackaging: boolean;

  @Output()
  updatedProduct = new EventEmitter<ProductResponse>();

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProductAvailabilityPricingFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {product: ProductResponse},
              private productsApiService: ProductsApiService,
              private attributesService: AttributesService,
              private loader: LoaderService,
              private dictionariesService: DictionariesService,
              public adminViewService: AdminViewService) {
  }

  ngOnInit(): void {
    this.product = this.data.product;
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.isAdminView = this.adminViewService.isAdminView;
    this.resolveVisibility();
    this.dictionariesService.loaded$.subscribe(() => {
      this.form = this.fb.group({
        pricing: new FormControl(this.product?.pricingInfo?.pricing),
        capacity: new FormControl(this.product?.pricingInfo?.productionCapacity),
        shippingDestination: new FormControl(this.product?.pricingInfo?.shippingDestination),
        leadTime: new FormControl(this.product?.pricingInfo?.leadTime),
        sampleCost: new FormControl(this.product?.pricingInfo?.sampleCost, [Validators.required]),
        shippingCost: new FormControl(this.product?.pricingInfo?.shippingCost),
        packaging: new FormControl(this.product?.pricingInfo.packaging),
        sampleDescription: new FormControl(this.product?.pricingInfo?.sampleDescription),
      })
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  submit(): void {
    this.loader.show();
    const {pricing, capacity, leadTime, shippingDestination, sampleCost, shippingCost, packaging, sampleDescription} = this.form.value;
    this.productsApiService.updateProductPricing({
      id: this.product?.id || '',
      productPricingInfo: {
        pricing: pricing,
        sampleCost: sampleCost,
        shippingCost: shippingCost,
        sampleDescription: sampleDescription,
        productionCapacity: capacity,
        leadTime: leadTime,
        packaging: packaging,
        shippingDestination: shippingDestination,
      }
    }).pipe(finalize(() => this.loader.setVisible(false)))
      .subscribe((product: ProductResponse) => {
      this.updatedProduct.emit(product);
      this.dialogRef.close();
    });
  }

  private resolveVisibility(): void {
    this.setPricingVisibility();
    this.setShippingDestinationVisibility();
    this.setLeadTimeVisibility();
    this.setCapacityVisibility();
    this.setPackagingVisibility();
  }

  private setPricingVisibility(): void {
    this.showPricing = this.attributesService.isShownProductAttribute('pricing');
  }

  private setShippingDestinationVisibility(): void {
    this.showShippingDestination = this.attributesService.isShownProductAttribute('shippingDestination');
  }

  private setLeadTimeVisibility(): void {
    this.showLeadTime = this.attributesService.isShownProductAttribute('leadTime');
  }

  private setCapacityVisibility(): void {
    this.showCapacity = this.attributesService.isShownProductAttribute('productionCapacity');
  }

  private setPackagingVisibility(): void {
    this.showPackaging = this.attributesService.isShownProductAttribute('packaging');
  }

  onlyNumbers($event: KeyboardEvent) {
    ValidatorsService.onlyNumbers($event);
  }
}
