import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {finalize} from 'rxjs';
import {
    Campaign,
    CompanyProfile,
    DictionaryValue,
    ProductResponse,
    ProductsApiService,
    ProductState
} from '../../../api/cs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DictionariesService} from '../../../shared/services/dictionaries.service';
import {LoaderService} from '../../../shared/services/loader.service';
import {ConfirmationWindowComponent} from "../../../core/components/confirmation-window/confirmation-window.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ToastService} from "../../../shared/services/toast.service";
import {ProductImageService} from "../../product-image.service";

@Component({
  selector: 'cs-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {

  form: FormGroup;
  typeForm: FormGroup;
  categoryForm: FormGroup;
  algaeTypeForm: FormGroup;
  customerFocusForm: FormGroup;
  platformForm: FormGroup;

  embeddedFormInitialised: boolean = false;

  @Output() productResponse = new EventEmitter<Campaign>();

  private company: CompanyProfile;
  private requiredFields: string[] = ['name', 'sampleCost'];
  types: string[];
  chassis: string[];
  categories: string[];
  algaeTypes: string[];
  customerFocuses: string[];

  constructor(private fb: FormBuilder,
              private loader: LoaderService,
              private dictionariesService: DictionariesService,
              private productImageService: ProductImageService,
              private productsApiService: ProductsApiService,
              private dialog: MatDialog,
              private dialogRef: MatDialogRef<ProductCreateComponent>,
              private toastService: ToastService,
              @Inject(MAT_DIALOG_DATA) private data: {
                company: CompanyProfile,
                product?: ProductResponse
              },
  ) { }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => this.confirmClose());
    this.company = this.data.company;
    this.setPropertyValues();
    this.initForm();
  }

  private initForm(): void {

    this.dictionariesService.loaded$.subscribe(() => {
      const bioSources = this.fb.group({});
      this.getBioSources().forEach((source) => {
        if (source.key != null) {
          bioSources.addControl(source.key, new FormControl(false));
        }
      });

      const industryFocuses = this.fb.group({});
      this.getIndustryFocuses().forEach((industryFocus) => {
        if (industryFocus.key != null) {
          industryFocuses.addControl(industryFocus.key, new FormControl(false));
        }
      });

      const materialTypes = this.fb.group({});
      this.getMaterialTypes().forEach((type) => {
        if (type.key != null) {
          materialTypes.addControl(type.key, new FormControl(false));
        }
      });

      const format = this.fb.group({});
      this.getFormats().forEach((form) => {
        if (form.key != null) {
          format.addControl(form.key, new FormControl(false));
        }
      });

      this.form = this.fb.group({
        name: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z0-9\- ]*')]),
        bioContent: new FormControl(null, [Validators.pattern('^[0-9]*[.,]?[0-9]+$'), Validators.max(100)]),
        description: [],
        capacity: [],
        category: this.categoryForm,
        type: this.typeForm,
        processing: new FormControl(this.dictionariesService.getDictionaryByName('product-processing', true)[0]?.key),
        format: format,
        platform: this.platformForm,
        algaeType: this.algaeTypeForm,
        customerFocus: this.customerFocusForm,
        developmentStage: new FormControl(this.dictionariesService.getDictionaryByName('product-development-stage', true)[0]?.key, Validators.required),
        leadTime: [],
        recommendation: [],
        productReplacement: [],
        pricing: [],
        sampleCost: new FormControl(''),
        shippingCost: [],
        sampleDescription: [],
        shippingDestination: [],
        bioSources: bioSources,
        industryFocuses: industryFocuses,
        materialTypes: materialTypes,
        images: new FormControl([]),
        imageRef: new FormControl(''),
      })
    });
  }

  private setPropertyValues(): void {

    this.typeForm = this.fb.group({});
    this.categoryForm = this.fb.group({});
    this.algaeTypeForm = this.fb.group({});
    this.customerFocusForm = this.fb.group({});
    this.platformForm = this.fb.group({});

    this.productsApiService.getProductChassisValues().subscribe(response => {
      this.chassis = response;
      this.chassis.forEach((platform) => {
        if (platform != null) {
          this.platformForm.addControl(platform, new FormControl());
        }
      });
    });
    this.productsApiService.getProductGeneralValues().subscribe(response => {
      this.types = response.type || [];
      response.type.forEach((value) => {
        if (value != null) {
          this.typeForm.addControl(value, new FormControl());
        }
      });
      this.categories = response.category || [];
      response.category?.forEach((value) => {
        if (value != null) {
          this.categoryForm.addControl(value, new FormControl());
        }
      });
      this.algaeTypes = response.algaeType || [];
      response.algaeType?.forEach((value) => {
        if (value != null) {
          this.algaeTypeForm.addControl(value, new FormControl());
        }
      });
      this.customerFocuses = response.customerFocus || [];
      response.customerFocus?.forEach((value) => {
        if (value != null) {
          this.customerFocusForm.addControl(value, new FormControl());
        }
      });
      this.embeddedFormInitialised = true;
    });
  }

  submit(): void {
    for(let field of this.requiredFields) {
      this.form.get(field)!.markAsTouched();
    }
    let inputs = Array.from(document.querySelectorAll('input'));
    for (let input of inputs) {
      if (input.getAttribute("class")?.includes("invalid")) {
        input.scrollIntoView({block: 'center' });
        return;
      }
    }
    this.loader.show();
    const formValue = this.form.value;
    this.productImageService.processImages(formValue.images).then(images => {
      this.productsApiService.createProduct({
        productRequest: {
          companyId: this.company.id || '',
          headingInfo: {
            name: formValue.name,
            description: formValue.description,
            developmentStage: this.getSelectedDictionaryValue("product-development-stage", formValue.developmentStage) || {},
            chassis: formValue.platform || {},
            state: formValue.state || ProductState.ACTIVE,
            images: images
          },
          generalInfo: {
            bioBasedContentPart: +formValue.bioContent,
            recommendation: formValue.recommendation,
            replaces: formValue.productReplacement,
            category: formValue.category,
            industryFocuses: this.getCheckedDictionaryValues('common-industry-focus', 'industryFocuses'),
            type: formValue.type,
            format: this.getCheckedDictionaryValues('product-format', 'format'),
          },
          pricingInfo: {
            productionCapacity: formValue.capacity,
            leadTime: formValue.leadTime,
            pricing: formValue.pricing,
            sampleCost: formValue.sampleCost,
            shippingCost: formValue.shippingCost,
            shippingDestination: formValue.shippingDestination,
            sampleDescription: formValue.sampleDescription,
          }
        }
      }).pipe(finalize(() => this.loader.hide()))
          .subscribe({
                next: (res) => {
                  this.dialogRef.close();
                  this.toastService.success("Product has been created");
                  this.productResponse.emit(res);
                  this.data.company.products?.push(res);
                },
                error: () => {
                  // TODO handle error
                }
              },
          );
    });
  }

  confirmClose(): void {
    let confirmation = this.dialog.open(ConfirmationWindowComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        header: "Window Close Confirmation",
        description: "discard changes and close the window"}
    });
    confirmation.beforeClosed().subscribe(() => {
      if (confirmation.componentInstance.confirmed.getValue()) {
        this.dialogRef.close();
      }
    })
  }

  getSelectedDictionaryValue(dictionaryName: string, selectedValue: string): DictionaryValue | undefined {
    if (!dictionaryName || !selectedValue) {
      return undefined;
    }
    return this.dictionariesService.getDictionaryByName(dictionaryName).find(value => value.key = selectedValue);
  }

  getCheckedDictionaryValues(dictionaryName: string, controlName: string): DictionaryValue[] {
    const control = this.form.get(controlName) as FormGroup;
    if (!dictionaryName || !control) {
      return [];
    }
    let values = Object.keys(control.controls).filter((key) => control.value[key]);
    return this.dictionariesService.getDictionaryByName(dictionaryName).filter(value => values.includes(value.key || ''));
  }

  getRegions(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('сompany-region', true);
  }

  getBioSources(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('product-bio-source', true);
  }

  getIndustryFocuses(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('common-industry-focus', true);
  }

  getMaterialTypes(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('product-material-type', true);
  }

  getFormats(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('product-format', true);
  }
}
