import {Component, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DictionariesService} from '../../../../shared/services/dictionaries.service';
import {CompaniesApiService, CompanyProfile, DictionaryValue, LocationApiService} from '../../../../api/cs';
import {AuthService} from '../../../../shared/services/auth.service';
import {AttributesService} from '../../../../shared/services/attributes.service';
import {AdminViewService} from "../../../../shared/services/admin-view.service";
import {GoogleMapsService} from "../../../../shared/services/google-maps.service";
import {ValidatorsService} from "../../../../core/util/validators.service";

@Component({
  selector: 'cs-edit-company-general-info-form',
  templateUrl: './edit-company-general-info-form.component.html',
  styleUrls: ['./edit-company-general-info-form.component.scss']
})
export class EditCompanyGeneralInfoFormComponent implements OnInit {

  form: FormGroup;
  marketRoleForm: FormGroup;
  algaeTypeForm: FormGroup;
  customerFocusForm: FormGroup;
  typeForm: FormGroup;

  private _isModalActive = false;
  private company: CompanyProfile;
  public predictions: string[];
  isAdminView: boolean;
  companySizesValues: DictionaryValue[];

  marketRoles: string[];
  algaeTypes: string[];
  customerFocuses: string[];
  types: string[];

  @ViewChild('address') addressInputElement!: ElementRef<HTMLInputElement>;

  @Output()
  updated = new EventEmitter<boolean>();

  @Output()
  viewChanged = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private attributesService: AttributesService,
              private companiesApiService: CompaniesApiService,
              private dictionariesService: DictionariesService,
              private locationApiService: LocationApiService,
              private googleMapsService: GoogleMapsService,
              public adminViewService: AdminViewService) {
  }

  ngOnInit(): void {
    this.initForm();
    this.googleMapsService.assumed.subscribe(assumption => this.form.get('location')?.setValue(assumption));
    this.googleMapsService.predicted.subscribe((predictions) => this.predictions = predictions);
  }

  private getCompanyPropertiesValues(): void {
    this.companiesApiService.getCompanyPropertiesValues().subscribe(response => {
      this.marketRoles = response.marketRole;
      response.marketRole?.forEach((value) => {
        if (value != null) {
          this.marketRoleForm.addControl(value,
              new FormControl(this.company?.marketRole?.includes(value)));
        }
      });
      this.algaeTypes = response.algaeType;
      response.algaeType?.forEach((value) => {
        if (value != null) {
          this.algaeTypeForm.addControl(value,
              new FormControl(this.company?.algaeType?.includes(value)));
        }
      });
      this.customerFocuses = response.customerFocus;
      response.customerFocus?.forEach((value) => {
        if (value != null) {
          this.customerFocusForm.addControl(value,
              new FormControl(this.company?.customerFocus?.includes(value)));
        }
      });
      this.types = response.type;
      response.type?.forEach((value) => {
        if (value != null) {
          this.typeForm.addControl(value,
              new FormControl(this.company?.type?.includes(value)));
        }
      });
    });
  }

  private initForm(): void {
    this.marketRoleForm = this.fb.group({});
    this.algaeTypeForm = this.fb.group({});
    this.customerFocusForm = this.fb.group({});
    this.typeForm = this.fb.group({});
    this.form = this.fb.group({
      location: [],
      companySize: [],
      foundationYear: [],
      ...this.showPhone ? {
        phone: []
      } : {},
      ...this.showWebsite ? {
        website: []
      } : {},
      ...this.showAddress ? {
        address: []
      } : {},
      marketRole: this.marketRoleForm,
      algaeType: this.algaeTypeForm,
      customerFocus: this.customerFocusForm,
      type: this.typeForm
    })
  }

  get isModalActive(): boolean {
    return this._isModalActive;
  }

  set isModalActive(value: boolean) {
    this._isModalActive = value;
  }

  get showAddress(): boolean {
    return this.authService.isAdmin();
  }

  get showWebsite(): boolean {
    return this.authService.isAdmin();
  }

  get showPhone(): boolean {
    return this.authService.isAdmin();
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }

  closeWindow(): void {
    this.isModalActive = false;
    this.assume();
  }

  showWindow(company?: CompanyProfile): void {
    this.isAdminView = this.adminViewService.isAdminView;
    this.adminViewService.adminView.subscribe(isAdminView => this.isAdminView = isAdminView);
    if (company) {
      this.company = company;
      this.dictionariesService.loaded$.subscribe(() => {
        this.companySizesValues = this.getCompanySizes();
      });

      this.form.reset();
      this.form.get('location')?.setValue(company.location);
      this.form.get('foundationYear')?.setValue(company.yearFounded);
      this.form.get('companySize')?.setValue(company.companySize?.key);

      if (this.showAddress) {
        this.form.get('address')?.setValue(company.address);
      }

      if (this.showWebsite) {
        this.form.get('website')?.setValue(company.website);
      }

      if (this.showPhone) {
        this.form.get('phone')?.setValue(company.phoneNumber);
      }
      this.getCompanyPropertiesValues();
    }
    this.isModalActive = true;
  }

  assume(): void {
    const {address} = this.form.value;
    if (address) {
      this.googleMapsService.assume(this.company.id || '', address, true);
    }
    this.predictions = [];
  }

  predict(address: string): void {
    if (this.form.get('address')?.dirty) {
      if (address) {
        this.googleMapsService.predict(this.company.id || '', address);
      }
    }
  }

  insert(prediction: string): void {
    if (this.form.get('address')?.value != prediction) {
      this.form.get('address')?.setValue(prediction);
    } else {
      this.predictions = [];
    }
    this.addressInputElement.nativeElement.focus();
  }

  submit(): void {
    const {companySize, foundationYear, location, address, website, phone,
      marketRole, algaeType, customerFocus, type} = this.form.value;
    this.companiesApiService.updateCompanyGeneral({
      id: this.company.id ?? '',
      companyGeneralUpdateRequest: {
        companySize,
        yearFounded: foundationYear,
        location,
        ...this.showPhone ? {
          phone: phone
        } : {},
        ...this.showAddress ? {
          address: address
        } : {},
        ...this.showWebsite ? {
          website: website
        } : {},
        marketRole: this.convertFormControlToJsonArray(marketRole),
        algaeType: this.convertFormControlToJsonArray(algaeType),
        customerFocus: this.convertFormControlToJsonArray(customerFocus),
        type: this.convertFormControlToJsonArray(type)
      }
    }).subscribe(() => {
      this.updated.emit(true);
      this.closeWindow();
    });
  }

  private convertFormControlToJsonArray(group: any): Array<string> {
    return Array.from(Object.keys(group).filter((key: string) => group[key]).map(key => key.toString()));
  }

  getCompanySizes(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('company-size', true, Array.of(this.company?.companySize || {}));
  }

  get showLocation(): boolean {
    return this.attributesService.isShownCompanyAttribute('location');
  }

  get showSize(): boolean {
    return this.attributesService.isShownCompanyAttribute('companySize');
  }

  get showFoundationYear(): boolean {
    return this.attributesService.isShownCompanyAttribute('yearFounded');
  }
}
