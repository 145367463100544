/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyVerificationStatusValue } from './company-verification-status-value';
import { CompanyVisibilityStatusValue } from './company-visibility-status-value';


export interface Company {
    id?: string;
    name?: string;
    alias?: string;
    tagline?: string;
    logoImage?: string;
    visibilityStatus?: CompanyVisibilityStatusValue;
    verificationStatus?: CompanyVerificationStatusValue;
    buyer?: boolean;
    supplier?: boolean;
}

