<div [formGroup]="form" *ngIf="dictionariesServiceLoaded$ | async">
  <div class="field pt-4 pb-4">
    <label class="label">Name:*</label>
    <div class="control">
      <input class="input is-medium" type="text" placeholder="Product Name" formControlName="name">
    </div>
    <span
      *ngIf="!form.get('name')!.valid && form.get('name')!.touched"
      class="help-block">Enter valid product name</span>
  </div>

  <div class="field pb-4">
    <label class="label">Description:</label>
    <div class="control">
      <textarea class="textarea is-medium"
                placeholder="Product Description"
                formControlName="description"
      ></textarea>
    </div>
  </div>

  <div class="field pt-4">
    <label class="label">Images:</label>
    <div class="file has-name is-right is-fullwidth">
      <label class="file-label">
        <input class="file-input" type="file" accept="image/*"
               formControlName="imageRef" multiple="multiple"
               (change)="onFileChoose($event)">
        <span class="file-cta">
              <span class="file-icon">
                <i class="fa fa-upload"></i>
              </span>
              <span class="file-label">
                Browse
              </span>
            </span>
        <span *ngIf="images.length == 0" class="file-name"></span>
        <span *ngFor="let image of images | orderBySequenceNumberAsc; let i = index" class="file-name is-flex is-align-items-center" (click)="images.splice(i, 1)">
              <img *ngIf="image" class="image-preview" [src]="getImagePreview(i)">
              <span class="ml-1"><i class="fa fa-remove"></i></span>
            </span>
      </label>
    </div>
    <label class="label mb-1 mt-2 error"
           *ngIf="form.controls['imageRef']?.errors?.hasOwnProperty('too-big') || false">Image size should
      be less than 10MB</label>
    <label class="label mb-1 mt-2 error"
           *ngIf="form.controls['imageRef']?.errors?.hasOwnProperty('big-amount-files') || false">Max amount of images - 6</label>
    <p class="help">
      Up to 6 files can be uploaded.
    </p>
    <p class="help">
      The file should be a <b>PNG</b> or a <b>JPEG</b>.
    </p>
    <p class="help">
      We recommend it to be at least 1024px on the long side. 4:3 ratio fits best.
    </p>
    <p class="help">
      Max 10MB
    </p>
  </div>

  <div class="field pb-4" formGroupName="type">
    <label class="label">Product Type:</label>
    <label class="checkbox cs-checkbox px-4"
           [class.checked]="form.value.type[type || '']"
           [class.mt-3]="!first"
           *ngFor="let type of getProductTypes(); let first = first"
    >
      <input type="checkbox" [formControlName]="type || ''">
      {{ type }}
    </label>
  </div>

  <div class="field pb-4" formGroupName="category">
    <label class="label">Product Category:</label>
    <label class="checkbox cs-checkbox px-4"
           [class.checked]="form.value.category?.[category || '']"
           [class.mt-3]="!first"
           *ngFor="let category of getCategories(); let first = first"
    >
      <input type="checkbox" [formControlName]="category || ''">
      {{ category }}
    </label>
  </div>

  <div class="field pb-4" formGroupName="algaeType">
    <label class="label">Product Algae Type:</label>
    <label class="checkbox cs-checkbox px-4"
           [class.checked]="form.value.algaeType?.[algaeType || '']"
           [class.mt-3]="!first"
           *ngFor="let algaeType of getAlgaeTypes(); let first = first"
    >
      <input type="checkbox" [formControlName]="algaeType || ''">
      {{ algaeType | toCamelCase }}
    </label>
  </div>

  <div class="field pb-4" formGroupName="customerFocus">
    <label class="label">Product Customer Focus:</label>
    <label class="checkbox cs-checkbox px-4"
           [class.checked]="form.value.customerFocus?.[customerFocus || '']"
           [class.mt-3]="!first"
           *ngFor="let customerFocus of getCustomerFocuses(); let first = first"
    >
      <input type="checkbox" [formControlName]="customerFocus || ''">
      {{ customerFocus | toCamelCase }}
    </label>
  </div>

  <div class="field pb-4" formGroupName="format">
    <label class="label">Formats:</label>
    <label class="checkbox cs-checkbox px-4"
           [class.checked]="form.value.format[format?.key || '']"
           [class.mt-3]="!first"
           *ngFor="let format of getFormats(); let first = first"
    >
      <input type="checkbox" [formControlName]="format.key || ''">
      {{format.value}}
    </label>
  </div>

  <div class="field pb-4" formGroupName="platform">
    <label class="label">Product Chassis:</label>
    <label class="checkbox cs-checkbox px-4"
           [class.checked]="form.value.platform[platform || '']"
           [class.mt-3]="!first"
           *ngFor="let platform of getChassis(); let first = first"
    >
      <input type="checkbox" [formControlName]="platform || ''">
      {{ platform }}
    </label>
  </div>

  <div class="field pb-4">
    <label class="label">Development Stage:</label>
    <div class="control">
      <div class="is-flex is-flex-direction-column">
        <label class="radio mx-0 cs-radio px-4"
               [for]="stage.key"
               [class.checked]="form.value.developmentStage == stage.key"
               [class.mt-3]="!first"
               *ngFor="let stage of getDevelopmentStages(); let first = first"
        >
          <input type="radio"
                 name="developmentStage"
                 [id]="stage.key"
                 [value]="stage.key"
                 formControlName="developmentStage"
                 [checked]="form.value.developmentStage == stage.key ? 'checked' : ''"
                 [defaultChecked]="form.value.developmentStage == stage.key ? 'checked' : ''"
          >
          {{stage.value}}
        </label>
      </div>
    </div>
  </div>

  <div class="field pt-4" formGroupName="industryFocuses">
    <label class="label">Target industry (check all that apply):</label>
    <label class="checkbox cs-checkbox px-4"
           [class.checked]="form.value.industryFocuses[industryFocus.key || '']"
           [class.mt-3]="!first"
           *ngFor="let industryFocus of getIndustryFocuses(); let first = first"
    >
      <input type="checkbox" [formControlName]="industryFocus.key || ''">
      {{industryFocus.value}}
    </label>
  </div>

  <div class="field pt-4 pb-4">
    <label class="label">Recommended use:</label>
    <div class="control">
      <input class="input is-medium"
             type="text"
             placeholder="What to use this product for?"
             formControlName="recommendation"
      >
    </div>
  </div>

  <div class="field pb-4">
    <label class="label">Product replacement:</label>
    <div class="control">
      <input class="input is-medium"
             type="text"
             placeholder="What product can it replace?"
             formControlName="productReplacement"
      >
    </div>
  </div>

  <div class="field pb-4">
    <label class="label">Bio-based Content %:</label>
    <div class="control">
      <input class="input is-medium" type="text" placeholder="99" formControlName="bioContent">
    </div>
    <span
      *ngIf="!form.get('bioContent')!.valid && form.get('bioContent')!.touched"
      class="help-block">Enter valid part of bio-based content</span>
  </div>

  <div class="field pb-4">
    <label class="label">Lead time:</label>
    <div class="control">
      <input class="input is-medium"
             type="text"
             placeholder="1 week"
             formControlName="leadTime"
      >
    </div>
  </div>

  <div class="field pb-4">
    <label class="label">Capacity:</label>
    <div class="control">
      <input class="input is-medium" type="text" placeholder="50 gallons per week" formControlName="capacity">
    </div>
  </div>

  <div class="field pb-4">
    <label class="label">Pricing:</label>
    <div class="control">
      <input class="input is-medium" type="text" placeholder="$2.00 per gallon" formControlName="pricing">
    </div>
  </div>

  <div id="shipping-line" class="field is-flex pt-4">
    <div class="field mb-0 is-flex-grow-1">
      <label class="label">Sample Cost:</label>
      <div class="control">
        <input class="input is-medium" type="text" (keypress)="onlyNumbers($event)" placeholder="$30" mask="separator.2" prefix="$"
               thousandSeparator="," formControlName="sampleCost">
      </div>
    </div>
    <div class="field is-flex-grow-1">
      <label class="label">Shipping Cost: (Not Active)</label>
      <div class="control">
        <input class="input is-medium" type="text" (keypress)="onlyNumbers($event)" placeholder="$15" mask="separator.2" prefix="$"
               thousandSeparator="," formControlName="shippingCost">
      </div>
    </div>
  </div>
  <div class="field pb-4">
    <label class="label">Ships to:</label>
    <div class="control">
      <input class="input is-medium"
             type="text"
             placeholder="Locations"
             formControlName="shippingDestination"
      >
    </div>
  </div>

  <div class="field pb-4">
    <label class="label">Sample Description:</label>
    <div class="control">
      <textarea class="textarea is-medium"
                placeholder="Sample Description"
                maxlength="200"
                formControlName="sampleDescription"
      ></textarea>
    </div>
  </div>
</div>

