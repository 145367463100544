import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DictionariesService} from '../../../../shared/services/dictionaries.service';
import {
  CompaniesApiService,
  CompanyProfile,
  CompanyPropertiesValues,
  CompanyVerificationStatusValue,
  DictionaryValue
} from '../../../../api/cs';
import {AttributesService} from '../../../../shared/services/attributes.service';
import {AdminViewService} from "../../../../shared/services/admin-view.service";
import {ValidatorsService} from "../../../../core/util/validators.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable, of} from "rxjs";

@Component({
  selector: 'cs-edit-company-main-info-form',
  templateUrl: './edit-company-main-info-form.component.html',
  styleUrls: ['./edit-company-main-info-form.component.scss']
})
export class EditCompanyMainInfoFormComponent implements OnInit {

  form: FormGroup;

  private company: CompanyProfile;
  private company$: Observable<CompanyProfile>;
  private requiredFields: string[] = ['name'];
  isAdminView: boolean;
  propertiesValues: CompanyPropertiesValues;

  @Output()
  updated = new EventEmitter<boolean>();

  @Output()
  viewChanged = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder,
              private dialogRef: MatDialogRef<EditCompanyMainInfoFormComponent>,
              private companiesApiService: CompaniesApiService,
              private attributesService: AttributesService,
              private dictionariesService: DictionariesService,
              @Inject(MAT_DIALOG_DATA) public data: {company?: CompanyProfile, companyId?: string},
              public adminViewService: AdminViewService) {
  }

  ngOnInit(): void {
    this.isAdminView = this.adminViewService.isAdminView;
    this.adminViewService.adminView.subscribe(isAdminView => this.isAdminView = isAdminView);

    if (!!this.data.company) {
      this.company$ = of(this.data.company);
    } else {
      this.company$ = this.companiesApiService.getCompany({id: this.data.companyId || ''});
    }
    const businessTypes = this.fb.group({});
    const platforms = this.fb.group({});
    const supplyChains = this.fb.group({});
    const industryFocuses = this.fb.group({});
    const customerFocuses = this.fb.group({});

    this.company$.subscribe(company => {
      this.company = company;

      if (!!this.form) {
        this.form.reset();
      }
      this.form = this.fb.group({
        name: new FormControl(company.name, [Validators.required, Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9\\s\\S]*')]),
        tagline: new FormControl(company.tagline),
        summary: new FormControl(company.description),
        verified: new FormControl(company.verificationStatus),
        supplyChains: supplyChains,
        businessTypes: businessTypes,
        platforms: platforms,
        industryFocuses: industryFocuses,
        customerFocuses: customerFocuses
      });

      this.dictionariesService.loaded$.subscribe(() => {
        this.getBusinessTypes().forEach((businessType) => {
          if (businessType.key != null) {
            businessTypes.addControl(businessType.key, new FormControl(false));
          }
        });

        this.getPlatforms().forEach((platform) => {
          if (platform.key != null) {
            platforms.addControl(platform.key, new FormControl(false));
          }
        });

        this.getSupplyChains().forEach((chain) => {
          if (chain.key != null) {
            supplyChains.addControl(chain.key, new FormControl(false));
          }
        });

        this.getIndustryFocuses().forEach((industryFocus) => {
          if (industryFocus.key != null) {
            industryFocuses.addControl(industryFocus.key, new FormControl(false));
          }
        });

        company.businessTypes?.filter((type) => type.key).forEach((businessType) => {
          this.form.get('businessTypes')?.get(businessType?.key ?? '')?.setValue(true);
        });

        company.platforms?.filter((plat) => plat.key).forEach((platform) => {
          this.form.get('platforms')?.get(platform?.key ?? '')?.setValue(true);
        })

        company.supplyChains?.filter((chain) => chain.key).forEach((supplyChain) => {
          this.form.get('supplyChains')?.get(supplyChain?.key ?? '')?.setValue(true);
        });

        company.industryFocuses?.filter((focus) => focus.key).forEach((focus) => {
          this.form.get('industryFocuses')?.get(focus?.key ?? '')?.setValue(true);
        });
      });

      this.companiesApiService.getCompanyPropertiesValues().subscribe(async values => {
        this.propertiesValues = values;
        this.getCustomerFocuses()?.forEach((focus) => {
          if (!!focus) {
            customerFocuses.addControl(focus, new FormControl(false));
          }
        });

        company.customerFocus?.forEach(focus =>
          this.form.get('customerFocuses')?.get(focus)?.setValue(true));
      });
    });
  }

  submit(): void {
    for(let field of this.requiredFields) {
      this.form.get(field)!.markAsTouched();
    }
    let inputs = Array.from(document
      .getElementById("main-info-modal-card")!
      .querySelectorAll('input'));
    for (let input of inputs) {
      if (input.getAttribute("class")?.includes("invalid")) {
        input.scrollIntoView({block: 'center' });
        return;
      }
    }
    const {name, tagline, summary} = this.form.value;
    this.companiesApiService.updateCompanyAbout({
      id: this.company.id || '',
      companyAboutUpdateRequest: {
        name,
        tagline,
        description: summary,
        businessTypes: this.getCheckedItemsKeys('businessTypes'),
        platforms: this.getCheckedItemsKeys('platforms'),
        supplyChains: this.getCheckedItemsKeys('supplyChains'),
        industryFocuses: this.getCheckedItemsKeys('industryFocuses'),
        customerFocuses: this.getCheckedItemsKeys('customerFocuses')
      }
    }).subscribe(() => {
      this.updated.emit(true);
      this.dialogRef.close();
    });
  }

  isVerified(): boolean {
    return this.company?.verificationStatus != CompanyVerificationStatusValue.UNVERIFIED;
  }

  getDevelopmentStages() {
    return this.dictionariesService.getDictionaryByName('product-development-stage')
  }

  getCheckedItemsKeys(controlName: string): string[] {
    const control = this.form.get(controlName) as FormGroup;
    if (!control) {
      return [];
    }

    return Object.keys(control.controls).filter((key) => control.value[key]);
  }

  getCustomerFocuses(): string[] | undefined {
    return this.propertiesValues?.customerFocus;
  }

  getSupplyChains(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('company-supply-chain', true, this.data.company?.supplyChains);
  }

  getBusinessTypes(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('company-business-type', true, this.data.company?.businessTypes);
  }

  getIndustryFocuses(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('common-industry-focus', true, this.data.company?.industryFocuses);
  }

  getPlatforms(): DictionaryValue[] {
    return this.dictionariesService.getDictionaryByName('common-platform', true, this.data.company?.platforms);
  }

  get showSummary(): boolean {
    return this.attributesService.isShownCompanyAttribute('description')
  }

  get showTagline(): boolean {
    return this.attributesService.isShownCompanyAttribute('tagline')
  }

  get showBusinessType(): boolean {
    return this.attributesService.isShownCompanyAttribute('businessTypes')
  }

  get showPlatforms(): boolean {
    return this.attributesService.isShownCompanyAttribute('platforms')
  }

  get showSupplyChains(): boolean {
    return this.attributesService.isShownCompanyAttribute('supplyChains')
  }

  get showIndustryFocuses(): boolean {
    return this.attributesService.isShownCompanyAttribute('industryFocuses')
  }

  get showCustomerFocuses(): boolean {
    return !!this.propertiesValues?.customerFocus;
  }

  get showVerified(): boolean {
    return this.attributesService.isShownCompanyAttribute('verificationStatus');
  }

  onlyEnglish($event: KeyboardEvent) {
    ValidatorsService.onlyEnglish($event);
  }
}
