<section class="container">
  <h1 class="hidden">Procurement<br />for the <span class="green-text bioeconomy">Bioeconomy</span></h1>
  <h2 class="hidden">Procurement for the bioeconomy. Cultured Supply has built a global network of suppliers transforming the way we make things.</h2>
  <div class="is-flex is-flex-direction-column is-align-items-center has-text-centered titles">
    <div class="titles__title"><span class="green-text has-text-weight-bold">Algae</span> Supply Chain Builder</div>
    <div class="titles__subtitle">Use the free resource to find inputs along the entire algae value chain.</div>
  </div>
</section>
<section class="container is-flex is-flex-direction-column is-align-items-center">
  <div class="numbers is-flex">
    <div class="numbers__companies is-flex">
      <div class="numbers__icon"><img src="/assets/images/icons/companies.png" /></div>
      <div class="numbers__text">
        <div class="numbers__number">{{companiesAmount}}</div> companies
      </div>
    </div>
    <div class="numbers__products is-flex">
      <div class="numbers__icon"><img src="/assets/images/icons/products.png" /></div>
      <div class="numbers__text">
        <div class="numbers__number">{{productsAmount}}</div> products
      </div>
    </div>
  </div>
  <div>
    <button class="button explore-button is-success has-text-weight-bold is-flex-grow-1" (click)="openAuthenticationModal()">Explore the builder</button>
  </div>
  <div class="list-your-company">
    Can't find your company? <a (click)="openAddYourCompanyModal()">List it today!</a>
  </div>
</section>
<section class="container">
  <div class="partnership is-flex is-flex-direction-column is-align-items-center">
    <div>In collaboration with:</div>
    <img src="/assets/images/phyconomy-logo.jpg" alt="Phyconomy. Tracking the seaweed economy" />
  </div>
</section>
