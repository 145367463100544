<div class="modal" [class.is-active]="isModalActive">
  <div class="modal-background" (click)="closeWindow()"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title has-text-centered has-text-weight-bold">
        Edit Company General Info
      </p>
      <span class="icon cs-icon-button" aria-label="close" (click)="closeWindow()">
        <i class="fa fa-lg fa-times"></i>
      </span>
    </header>
    <section class="modal-card-body pt-6" [formGroup]="form" *ngIf="form">

      <div class="field" *ngIf="showWebsite">
        <label class="label">Website:</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 placeholder="www.example.com"
                 formControlName="website"
          />
          <p class="mt-2 field-access-hint">
            Visible only for company owners and admin
          </p>
        </div>
      </div>
      <div class="field" *ngIf="showPhone">
        <label class="label">Phone Number*:</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 placeholder="+123456789000"
                 formControlName="phone"
          />
          <p class="mt-2 field-access-hint">
            Visible only for company owners and admin
          </p>
        </div>
      </div>
      <div class="field pb-4 is-flex-grow-1" *ngIf="showAddress">
        <label class="label">Address*:</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 placeholder="address, city, country"
                 aria-label="Delivery address"
                 matInput
                 formControlName="address"
                 [matAutocomplete]="auto"
                 (keypress)="onlyEnglish($event)"
                 (ngModelChange)="predict($event)">
          <p class="mt-2 field-access-hint">
            Visible only for company owners and admin
          </p>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="insert($event.option.value)">
            <mat-option *ngFor="let option of predictions" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>

      <div class="field pb-4" *ngIf="showLocation">
        <label class="label">Location*:</label>
        <div class="control">
          <input class="input is-medium"
                 type="text"
                 placeholder="Castellon, Spain"
                 formControlName="location"
                 (keypress)="onlyEnglish($event)"
                 (click)="assume()"
          />
        </div>
      </div>

      <div class="field pb-4 pt-4" *ngIf="showSize">
        <label class="label">Company Size:</label>
        <div class="control">
          <div class="is-flex is-flex-direction-column">
            <label class="radio mx-0 cs-radio px-4"
                   [for]="size.key"
                   [class.checked]="form.value.companySize == size.key"
                   [class.mt-3]="!first"
                   *ngFor="let size of companySizesValues; let first = first"
            >
              <input type="radio"
                     name="companySize"
                     [id]="size.key"
                     [value]="size.key"
                     formControlName="companySize"
                     [checked]="form.value.type == size.key ? 'checked' : ''"
                     [defaultChecked]="form.value.type == size.key ? 'checked' : ''"
              >
              {{size.value}}
            </label>
          </div>
        </div>
      </div>

      <div class="field pb-4" *ngIf="showFoundationYear">
        <label class="label">Year Founded:</label>
        <div class="control">
          <input class="input is-medium"
                 type="number"
                 placeholder="2006"
                 formControlName="foundationYear"
          />
        </div>
      </div>

      <div class="field pb-4" formGroupName="marketRole">
        <label class="label">Company Market Role:</label>
        <label class="checkbox cs-checkbox px-4"
               [class.checked]="form.value.marketRole?.[marketRole || '']"
               [class.mt-3]="!first"
               *ngFor="let marketRole of marketRoles; let first = first"
        >
          <input type="checkbox" [formControlName]="marketRole || ''">
          {{ marketRole | toCamelCase }}
        </label>
      </div>

      <div class="field pb-4" formGroupName="algaeType">
        <label class="label">Company Algae Type:</label>
        <label class="checkbox cs-checkbox px-4"
               [class.checked]="form.value.algaeType?.[algaeType || '']"
               [class.mt-3]="!first"
               *ngFor="let algaeType of algaeTypes; let first = first"
        >
          <input type="checkbox" [formControlName]="algaeType || ''">
          {{ algaeType | toCamelCase }}
        </label>
      </div>

      <div class="field pb-4" formGroupName="customerFocus">
        <label class="label">Company Customer Focus:</label>
        <label class="checkbox cs-checkbox px-4"
               [class.checked]="form.value.customerFocus?.[customerFocus || '']"
               [class.mt-3]="!first"
               *ngFor="let customerFocus of customerFocuses; let first = first"
        >
          <input type="checkbox" [formControlName]="customerFocus || ''">
          {{ customerFocus | toCamelCase }}
        </label>
      </div>

      <div class="field pb-4" formGroupName="type">
        <label class="label">Company Type:</label>
        <label class="checkbox cs-checkbox px-4"
               [class.checked]="form.value.type?.[type || '']"
               [class.mt-3]="!first"
               *ngFor="let type of types; let first = first"
        >
          <input type="checkbox" [formControlName]="type || ''">
          {{ type | toCamelCase }}
        </label>
      </div>

      <div class="field is-grouped is-grouped-centered mt-6">
        <div class="control py-4">
          <a class="button wide is-success has-text-weight-bold is-fullwidth"
             (click)="submit()"
          >
            Save
          </a>
        </div>
      </div>
    </section>
  </div>
</div>
