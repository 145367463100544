/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompanyVerificationStatusValue } from './company-verification-status-value';
import { CompanyVisibilityStatusValue } from './company-visibility-status-value';
import { Campaign } from './campaign';
import { User } from './user';
import { DictionaryValue } from './dictionary-value';
import { CompanyProduct } from './company-product';


export interface CompanyProfile {
    id?: string;
    visibilityStatus?: CompanyVisibilityStatusValue;
    verificationStatus?: CompanyVerificationStatusValue;
    buyer?: boolean;
    supplier?: boolean;
    name?: string;
    alias?: string;
    tagline?: string;
    description?: string;
    logoImage?: string;
    address?: string;
    location?: string;
    yearFounded?: number;
    website?: string;
    phoneNumber?: string;
    productionCapacity?: string;
    certification?: string;
    rndCapabilities?: string;
    companySize?: DictionaryValue;
    region?: Array<DictionaryValue>;
    businessTypes?: Array<DictionaryValue>;
    supplyChains?: Array<DictionaryValue>;
    industryFocuses?: Array<DictionaryValue>;
    platforms?: Array<DictionaryValue>;
    products?: Array<CompanyProduct>;
    campaigns?: Array<Campaign>;
    contact?: User;
    marketRole?: Array<string>;
    algaeType?: Array<string>;
    customerFocus?: Array<string>;
    type?: Array<string>;
}

